@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

* {
  font-family: "Libre Franklin", sans-serif;
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}


.hero {
  background-image: url("./images/lagos2.jpg");
  height: 87.8vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.heroTitle {
  font-family: 'Lato', sans-serif;
}

/* Animate slide styling */

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0));
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0));
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  /* display: inline-flex; */
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

#testimonial .owl-prev,
#testimonial .owl-next {
  font-size: 30px !important;
  padding: 0px 10px !important;
  /* color: white !important; */
}
